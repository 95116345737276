import { Injectable } from '@angular/core'
import { PageResultDto, ResultDto } from '../../dto'
import { HttpService } from '../../services'
import { AdminCreateProjectDto, AdminFindProjectDto, AdminProjectDetailDto, AdminProjectDto } from '../dto'

@Injectable()
export class AdminProjectService {
  constructor(
    private httpService: HttpService) {
  }

  async sortOrder(projectIds: string[]) {
    return await this.httpService.put<ResultDto>('/admin/v1/projects/reorder', { projectIds })
  }

  async findOwnerId(ownerId: string) {
    return await this.httpService.get<AdminProjectDto[]>('/admin/v1/projects/by-owner', { ownerId })
  }

  async find(adminFindProjectDto: AdminFindProjectDto) {
    return await this.httpService.get<AdminProjectDto[]>('/admin/v1/projects', adminFindProjectDto)
  }

  async sortOrderChild(id: string, projectIds: string[]) {
    return await this.httpService.put<ResultDto>(`/admin/v1/projects/${id}/reorder`, { projectIds })
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminProjectDetailDto>(`/admin/v1/projects/${id}`)
  }
  
  async create({ name, iconId }: Partial<AdminCreateProjectDto>)  {
    return await this.httpService.post<ResultDto>('/admin/v1/projects', { 
      name,
      iconId
    })
  }

  async createScopeOfWork(id: string, { name, iconId }: Partial<AdminCreateProjectDto>)  {
    return await this.httpService.post<ResultDto>(`/admin/v1/projects/${id}/scope-of-works`, { 
      name,
      iconId
    })
  }

  async createAssignee(id: string, ownerId: string) {
    return await this.httpService.post<ResultDto>(`/admin/v1/projects/${id}/project-assignment`, { 
      ownerId
    })
  } 

  async deleteById(id: string) {
    return await this.httpService.delete(`/admin/v1/projects/${id}`)
  }

  async deleteAssignmentId(id: string, assignmentId) {
    return await this.httpService.delete(`/admin/v1/projects/${id}/project-assignment/${assignmentId}`)
  }

  async update(id: string, updatePageDto: AdminCreateProjectDto) {
    return await this.httpService.put<ResultDto>(`/admin/v1/projects/${id}`, updatePageDto)
  }
}
