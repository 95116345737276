import { Injectable } from '@angular/core'
import { HttpService } from '../../services'
import { AdminDashboardDto, AdminDashboardProjectDto, AdminProjectReportDto, AdminReportDto, ExportExcelResultDto } from '../dto/admin-dashboard.dto'

@Injectable()
export class AdminDashboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find(projectId: string) {
    return await this.httpService.get<AdminDashboardDto>('/admin/v1/dashboard', { projectId })
  }

  async report(weekNumber: number, year: number) {
    return await this.httpService.get<AdminReportDto>('/admin/v1/dashboard/report', { weekNumber, year })
  }

  async projectReport(weekNumber: number, year: number) {
    return await this.httpService.get<AdminProjectReportDto[]>('/admin/v1/dashboard/project-report', { weekNumber, year })
  }

  async findScopeOfWork(projectId: string) {
    return await this.httpService.get<AdminDashboardProjectDto>('/admin/v1/dashboard/scope-of-work', { projectId })
  }

  async exportReport(projectId: string) {
    return await this.httpService.get<ExportExcelResultDto>('/admin/v1/dashboard/export-report', { projectId })
  }
}
