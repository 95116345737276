import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { ProjectDto } from '../dto'

@Injectable()
export class ProjectService {
  constructor(
    private httpService: HttpService) {
  }

  async getProjects() {
    return await this.httpService.get<ProjectDto[]>(`/v1/projects`)
  }

  async dashboard() {
    return await this.httpService.get<ProjectDto[]>(`/v1/projects/dashboard`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put<Boolean>(`/v1/projects/${id}/enable`)
    }
    
    return await this.httpService.put<Boolean>(`/v1/projects/${id}/disable`)
}
}
