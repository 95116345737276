import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { CreateTimesheetDto, ResultDto, SettingDto, TimesheetDto, WeeklyTimesheetDto } from '../dto'

@Injectable()
export class TimesheetService {
  constructor(
    private httpService: HttpService) {
  }

  async createTimesheet({ hour, weeklySheetId, projectId, weekCode }: Partial<CreateTimesheetDto>) {
    return await this.httpService.post<WeeklyTimesheetDto>('/v1/timesheets', { hour, weeklySheetId, projectId, weekCode })
  }
  
  async updateTimesheet(id: string, { hour }: Partial<CreateTimesheetDto>) {
    return await this.httpService.put<ResultDto>(`/v1/timesheets/${id}`, { hour })
  }

  async createWeeklyTimesheet({ weekNumber, year }: Partial<WeeklyTimesheetDto>) {
    return await this.httpService.post<WeeklyTimesheetDto>('/v1/timesheets/weekly-timesheet', { weekNumber, year })
  }

  async requestApproval(weeklyId: string) {
    return await this.httpService.put<WeeklyTimesheetDto>(`/v1/timesheets/weekly-timesheet/${weeklyId}/request-approval`)
  }

  async getTimeSheet(weeklyId: string) {
    return await this.httpService.get<WeeklyTimesheetDto>(`/v1/timesheets/weekly-timesheet/${weeklyId}`)
  }
}
