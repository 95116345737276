import { Injectable } from '@angular/core'
import { PageResultDto, ResultDto } from '../../dto'
import { HttpService } from '../../services'
import {  AdminFindWeeklyTimesheetDto, AdminWeeklyTimesheetDto } from '../dto'
import { AdminCountWeeklyTimesheetDto } from '../dto/admin-timesheet.dto'

@Injectable()
export class AdminTimesheetService {
  constructor(
    private httpService: HttpService) {
  }

  async find(adminFindWeeklyTimesheetDto: AdminFindWeeklyTimesheetDto) {
    return await this.httpService.get<PageResultDto<AdminWeeklyTimesheetDto>>('/admin/v1/timesheets/weekly-timesheet', adminFindWeeklyTimesheetDto)
  }

  async seen() {
    return await this.httpService.put<ResultDto>('/admin/v1/timesheets/weekly-timesheet/seen')
  }

  async unseenCount() {
    return await this.httpService.get<AdminCountWeeklyTimesheetDto>('/admin/v1/timesheets/weekly-timesheet/unseen-count')
  }

  async findOne(id: string) {
    return await this.httpService.get<AdminWeeklyTimesheetDto>(`/admin/v1/timesheets/weekly-timesheet/${id}`)
  }

  async approval(weeklyId: string) {
    return await this.httpService.put<ResultDto>(`/admin/v1/timesheets/weekly-timesheet/${weeklyId}/approval`)
  }

  async reject(weeklyId: string) {
    return await this.httpService.put<ResultDto>(`/admin/v1/timesheets/weekly-timesheet/${weeklyId}/reject`)
  }

}
